import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import back from "../assets/home/back.png";
import Tabs from "../components/Tabs";
import { BASE_URL } from "../config";

const Register = () => {
  const initialValue = {
    name: "",
    school: "",
    email: "",
    phone: "",
    classes: "",
    gender: "",
    city: "",
    teacher: "",
  };
  const navigate = useNavigate();
  const [loginType, setLoginType] = useState(1);
  const [formInput, setFormInput] = useState(initialValue);

  const handleChange = (e) => {
    setFormInput((preState) => {
      return { ...preState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setFormInput(initialValue);
    if (loginType === 1) {
      if (
        !formInput.name ||
        !formInput.school ||
        !formInput.email ||
        !formInput.phone ||
        !formInput.classes ||
        !formInput.gender ||
        !formInput.city
      ) {
        return alert("please enter required field");
      }
      callingAPI();
    }
    if (loginType === 2) {
      if (
        !formInput.name ||
        !formInput.email ||
        !formInput.phone ||
        !formInput.gender ||
        !formInput.city
      ) {
        return alert("please enter required field");
      }
      callingAPI();
    }
    if (loginType === 3) {
      if (
        !formInput.name ||
        !formInput.school ||
        !formInput.email ||
        !formInput.phone ||
        !formInput.gender ||
        !formInput.city
      ) {
        return alert("please enter required field");
      }
      callingAPI();
    }
  };

  const callingAPI = () => {
    const url = `${BASE_URL}/api/v1/register`;
    const formData = {
      deviceType: "phone",
      userType: loginType,
      name: formInput.name,
      phone: formInput.phone,
      email: formInput.email,
      schoolName: formInput.school,
      location: formInput.city,
      genderId: formInput.gender,
      classId: formInput.classes,
      loginType: "",
    };
    axios
      .post(url, formData)
      .then((response) => {
        alert(response.data.status.message);
        console.log(response.data);
        if (response.data.status.success) {
          navigate("/", {
            state: {
              verification: true,
              guest: false,
              type: true,
              user: formInput.phone,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="bg-container ai-center jc-center" style={styles.container}>
      <img
        src={require("src/assets/app_logo.png").default}
        style={styles.app_logo}
      />
      <div class="mainCard">
        <div class="imgWrap" style={styles.wrapImg}>
          <img
            src={require("src/assets/Finl.jpg").default}
            alt=""
            style={styles.image}
          />
        </div>
        <div className="card ai-center elevated" style={styles.card}>
          <img
            src={back}
            alt="img"
            width="20px"
            height="20px"
            style={styles.back_button}
            onClick={() => navigate("/")}
          />
          <h1>Register</h1>
          <br />
          <Tabs
            onChange={(i) => {
              setLoginType([1, 2, 3][i]);
            }}
            tabs={[
              {
                label: "Student",
                child: (
                  <>
                    <br />
                    <input
                      className="input"
                      placeholder="Enter Name"
                      style={styles.input}
                      name="name"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="School Name"
                      style={styles.input}
                      name="school"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="Enter email ID"
                      style={styles.input}
                      name="email"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="Enter Phone Number"
                      style={styles.input}
                      name="phone"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="City / Nearest Location"
                      style={styles.input}
                      name="city"
                      onChange={handleChange}
                    />
                    <select
                      className="input"
                      style={styles.select}
                      name="classes"
                      onChange={handleChange}
                    >
                      <option>----- Select Class -----</option>
                      {classesList.map((data, index) => (
                        <option key={index} value={index}>
                          {data}
                        </option>
                      ))}
                    </select>
                    <select
                      className="input"
                      style={styles.select}
                      name="gender"
                      onChange={handleChange}
                    >
                      <option>----- Select Gender -----</option>
                      {gender.map((data, index) => (
                        <option key={index} value={index}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </>
                ),
              },
              {
                label: "Individual",
                child: (
                  <>
                    <br />

                    <input
                      className="input"
                      placeholder="Enter Name"
                      style={styles.input}
                      name="name"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="Enter email ID"
                      style={styles.input}
                      name="email"
                      onChange={handleChange}
                    />

                    <input
                      className="input"
                      placeholder="Enter Phone Number"
                      style={styles.input}
                      name="phone"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="City / Nearest Location"
                      style={styles.input}
                      name="city"
                      onChange={handleChange}
                    />

                    <select
                      className="input"
                      style={styles.select}
                      name="gender"
                      onChange={handleChange}
                    >
                      <option>----- Select Gender -----</option>
                      {gender.map((data, index) => (
                        <option key={index} value={index}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </>
                ),
              },
              {
                label: "School",
                child: (
                  <>
                    <br />

                    <input
                      className="input"
                      placeholder="Teacher Name"
                      style={styles.input}
                      name="name"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="School Name"
                      style={styles.input}
                      name="school"
                      onChange={handleChange}
                    />

                    <input
                      className="input"
                      placeholder="Enter email ID"
                      style={styles.input}
                      name="email"
                      onChange={handleChange}
                    />
                    <input
                      className="input"
                      placeholder="Enter Phone Number"
                      style={styles.input}
                      name="phone"
                      onChange={handleChange}
                    />

                    <input
                      className="input"
                      placeholder="City / Nearest Location"
                      style={styles.input}
                      name="city"
                      onChange={handleChange}
                    />
                    <select
                      className="input"
                      style={styles.select}
                      name="gender"
                      onChange={handleChange}
                    >
                      <option>----- Select Gender -----</option>
                      {gender.map((data, index) => (
                        <option key={index} value={index}>
                          {data}
                        </option>
                      ))}
                    </select>
                  </>
                ),
              },
            ]}
          />
          <button type="primary" style={styles.button} onClick={handleSubmit}>
            Register
          </button>
        </div>
      </div>
    </div>
  );
};
const classesList = [
  "1st (I)",
  "2nd (II)",
  "3rd (III)",
  "4th (IV)",
  "5th (V)",
  "6th (VI)",
  "7th (VI)",
  "8th (VIII)",
  "9th (IX)",
  "10th (X)",
  "11th (XI)",
  "12th (XII)",
  "Graduate",
  "Post Graduate",
];
const gender = ["Male", "Female", "Other"];
const styles = {
  container: {
    overflow: "hidden",
    backgroundColor: "#E4E4E4",
  },
  app_logo: {
    position: "absolute",
    top: 32,
    right: 32,
    height: 32,
  },
  wrapImg: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  card: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    position: "relative",
  },
  input: {
    margin: "7px 5px",
  },
  select: {
    margin: "7px 5px",
  },
  button: {
    marginTop: 8,
    marginBottom: 8,
  },
  header: {
    display: "flex",
    fontSize: 24,
  },
  orLine: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  back_button: {
    position: "absolute",
    left: 30,
    top: 40,
    cursor: "pointer",
  },
};
export default Register;
