import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./Login";
import Register from "./Register";
import JoinMeet from "./JoinMeet";
import Dashboard from "./dashboard";
import GuestLogin from "./Login/GuestLogin";
import Donation from "./donation";
// import { auth } from "./config";
import { PrivateRoute } from "./privateRoute";
import ErrorPage from "./errorPage";
require("dotenv").config();

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/guest" element={<GuestLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/join" element={<JoinMeet />} />
        <Route path="/donation" element={<Donation />} />
        <Route
          path="/user/*"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
